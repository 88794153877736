<template>
  <div class="w-full h-full pt-5">
    <template>
      <template v-if="hasModuleAccess">
        <transition name="fade">
          <span
            class="shadow-lg p-3 w-1/4 text-white text-sm text-center absolute bg-desire"
            style="left: 43%; top: 66%"
            v-if="error"
          >
            Can't fetch request data
          </span>
        </transition>
        <div class="w-full flex flex-col">
          <Table
            :headers="requestHeader"
            :items="requestData"
            class="w-full mt-4 mb-4"
            :loading="loading"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div v-if="item.dateSubmitted">
                <p>
                  {{
                    $DATEFORMAT(
                      new Date(item.data.dateSubmitted),
                      "MMMM dd, yyyy"
                    )
                  }}
                </p>
              </div>
              <div v-else-if="item.approvalPercentage">
                <div class="flex flex-col">
                  <span class="flex">
                    <p class="font-bold text-darkPurple text-xl">
                      {{ Math.ceil(item.data.approvalPercentage) }}%
                    </p>
                    <p class="text-romanSilver ml-1 mt-1 text-sm">Completed</p>
                  </span>
                  <span class="flex">
                    <ProgressBar
                      :value="Math.ceil(item.data.approvalPercentage)"
                    />
                  </span>
                  <p class="font-semibold text-xs text-romanSilver uppercase">
                    {{ item.data.approversLeft }} approvers left
                  </p>
                </div>
              </div>
              <div
                v-else-if="item.requestId"
                @click="displayProgress(item.data.requestId)"
              >
                <Tag
                  class="text-flame py-2 px-2 mx-2 mt-5 text-sm cursor-pointer border"
                  style="width: 120px; border-color: #f15a29"
                >
                  View Progress
                </Tag>
              </div>
            </template>
          </Table>
        </div>
      </template>
      <div v-else>
        <ErrorComponent />
      </div>
    </template>
    <RightSideBar
      v-if="requestModal"
      @close="requestModal = false"
      close-button
    >
      <template v-slot:title>
        <p class="text-darkPurple">Approvers Trail</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple pb-4">
          Track approval progress here. See who has approved and what stage the
          approval flow is currently in...
        </p>
      </template>
      <div>
        <div style="height: 100%" v-if="loadingSide">
          <loader size="xxs" :loader-image="false" />
        </div>
        <div v-else>
          <div v-for="(item, index) in requestProgress" :key="index">
            <ApproversCard
              class="flex bg-white pt-2 px-3 pb-4 mb-2 even:bg-ghostWhite border-l-4"
              :style="approveDetails(item.hasApproved, item.currentApprover)"
            >
              <div class="flex w-full">
                <div class="flex w-auto pr-1 py-2 inline-block">
                  <img
                    :src="item.photo"
                    class="round-md"
                    style="height: 40px; width: 40px"
                    v-if="item.photo != null && item.photo != ''"
                  />
                  <div
                    style="height: 40px; width: 40px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                    v-else
                  >
                    {{ $getInitials(`${item.fname} ${item.lname}`) }}
                  </div>
                </div>
                <div class="flex flex-col w-3/4 mt-2">
                  <div class="leading-tight">
                    <span class="block text-lg leading-tight">
                      <span class="block text-base"
                        >{{ item.fname }} {{ item.lname }}</span
                      >
                      <span class="text-romanSilver uppercase text-xs block">
                        {{ item.userDesignation.name }}
                      </span>
                      <span class="block text-xs">{{
                        $DATEFORMAT(
                          new Date(item.actionTakenAt),
                          "MMMM dd, yyyy"
                        )
                      }}</span>
                    </span>
                  </div>
                  <div class="flex w-full mt-1 leading-tight">
                    <div class="w-full">
                      <span class="pr-2 text-sm break-words leading-tight">
                        <span
                          class="font-normal text-romanSilver leading-tight"
                        >
                          Comment:
                        </span>
                        <span class="leading-tight">
                          {{ item.comment }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex-none w-4/12 text-base">
                  <Tag
                    class="py-2 px-2 mx-2 float-right mt-8 text-sm"
                    :class="
                      approveBgColor(item.hasApproved, item.currentApprover)
                    "
                  >
                    {{ approveText(item.hasApproved, item.currentApprover) }}
                  </Tag>
                </div>
              </div>
            </ApproversCard>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import ProgressBar from "@/components/ProgressBar";
import RightSideBar from "@/components/RightSideBar";
import ApproversCard from "@/components/ApproversCard";
import Tag from "@/components/Tag";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "ViewRequests",
  components: {
    Table,
    ProgressBar,
    RightSideBar,
    ApproversCard,
    Tag,
    ErrorComponent,
  },
  data() {
    return {
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      isLoading: true,
      hasModuleAccess: false,
      requestModal: false,
      loading: true,
      loadingSide: true,
      error: false,
      requestHeader: [
        { title: "Date Submitted", value: "dateSubmitted" },
        { title: "Request", value: "requestName" },
        { title: "Approval Progress", value: "approvalPercentage" },
        { title: "Progress", value: "requestId" },
      ],
      requestData: [],
      requestProgress: [],
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getApprovalRequest(`${pageNumber}${itemPage}`);
    },

    async getApprovalRequest(query) {
      const params = `${this.$AuthUser.id}?${query}`;
      this.$_getMyRequests(params,'&platform=admin')
        .then((result) => {
          this.loading = false;
          this.requestData = result.data.approvals;
          this.metaData = result.data.meta;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    async displayProgress(requestId) {
      try {
        await this.$handlePrivilege("myRequests", "viewRequestsProgress");
        this.requestModal = true;
        this.loadingSide = true;
        this.$_getApprovalProgress(requestId)
          .then((result) => {
            this.loadingSide = false;
            this.requestProgress = result.data.data;
          })
          .catch((error) => {
            throw new Error(error);
          });
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    approveDetails(value, approver) {
      if (value === "true") {
        return "border-color: #13B56A;";
      }
      if (value === "false") {
        return "border-color: rgba(234, 60, 83, 1)";
      }
      if (value === null && approver === "active") {
        return "border-color: #E99323;";
      }
      return "border-color: #333333;";
    },
    approveText(value, approver) {
      if (value === "true") {
        return "Approved";
      }
      if (value === "false") {
        return "Disapproved";
      }
      if (value === null && approver === "active") {
        return "Pending";
      }
      return "Not Started";
    },
    approveBgColor(value, approver) {
      if (value === "true") {
        return "text-mediumSeaGreen bg-light-sea-green";
      }
      if (value === "false") {
        return "text-desire bg-light-flame-red";
      }
      if (value === null && approver === "active") {
        return "text-carrotOrange bgCarrot";
      }
      return "text-black bg-gray-200";
    },
  },
  async created() {
    this.isLoading = true;

    try {
      await this.$handlePrivilege("myRequests", "viewRegister");
      this.hasModuleAccess = true;
      await this.getApprovalRequest("&page=1&perPage=50");
    } catch (error) {
      this.hasModuleAccess = false;
    }

    this.isLoading = false;
  },
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
