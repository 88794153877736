<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <div class="w-full">
        <div class="w-full h-full">
          <div class="flex">
            <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
              Approval
            </h1>
            <Breadcrumb :items="breadcrumbs" />
          </div>
          <div class="w-full" v-if="hasModuleAccess">
            <div class="mx-3 mt-6">
              <Card class="mt-6 p-5">
                <div class="flex">
                  <StatCard
                    class="pb-4"
                    label="Total Requests"
                    :figure="totalRequest"
                    icon-card-bg="rgba(50, 28, 59, 0.08)"
                    color="rgba(50, 28, 59, 1)"
                    icon-name="shield"
                  />
                  <StatCard
                    class="pb-4"
                    label="Approved Requests"
                    :figure="approvedRequest"
                    icon-card-bg="rgba(19, 181, 106, 0.08)"
                    color="rgba(19, 181, 106, 1)"
                    icon-name="shield-approved"
                  />
                  <StatCard
                    class="pb-4"
                    label="Open Requests"
                    :figure="openRequest"
                    icon-card-bg="rgba(233, 147, 35, 0.08)"
                    color="rgba(233, 147, 35, 1)"
                    icon-name="shield-open"
                  />
                  <StatCard
                    class="pb-4"
                    label="Dissapproved Requests"
                    :figure="disapprovedRequest"
                    icon-card-bg="rgba(234, 60, 83, 0.08)"
                    color="rgba(234, 60, 83, 1)"
                    icon-name="shield-open"
                  />
                </div>
                <template v-slot:footer>
                  <card-footer />
                </template>
              </Card>
            </div>
            <div class="w-full h-full p-3">
              <loader v-if="isFetching" size="xxs" :loader-image="false" />
              <template v-else>
                <ViewRequests v-if="totalRequest > 0" />
                <EmptyRequests v-else />
              </template>
            </div>
          </div>
          <div v-else>
            <ErrorComponent />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import StatCard from "@/components/StatCard";
import Loader from "@/components/Loader";
import ErrorComponent from "@/modules/Admin/error403";
import EmptyRequests from "./emptyRequests";
import ViewRequests from "./viewRequest";

export default {
  name: "Requests",
  components: {
    Breadcrumb,
    Card,
    CardFooter,
    StatCard,
    ErrorComponent,
    EmptyRequests,
    ViewRequests,
    Loader
  },
  data() {
    return {
      isLoading: true,
      isFetching: true,
      hasModuleAccess: false,
      showError: false,
      hasApprovals: true,
      loading: true,
      openModal: false,
      totalRequest: "",
      approvedRequest: "",
      disapprovedRequest: "",
      openRequest: "",
      breadcrumbs: [
        {
          disabled: false,
          text: "Approval",
          href: "Approval",
          id: "Approval"
        },
        {
          disabled: false,
          text: "Requests",
          href: "Requests",
          id: "My Requests"
        }
      ]
    };
  },
  methods: {
    async getApprovalNumber() {
      const userId = this.$AuthUser.id;
      await this.$_getRequestNumbers(userId).then(result => {
          this.isFetching = false;
          this.totalRequest = result.data.TotalRequest;
          this.approvedRequest = result.data.ApprovedRequest;
          this.disapprovedRequest = result.data.DisapprovedRequests;
          this.openRequest = result.data.OpenRequest;
        })
        .catch(() => {
          this.isFetching = false;
        });
    },
  },
  async created() {
    this.isLoading = true

    try {
      await this.$handlePrivilege("myRequests","viewRegister")
      this.hasModuleAccess = true
      await this.getApprovalNumber()
    } catch (error) {
      this.hasModuleAccess = false
    }

    this.isLoading = false
  }
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
</style>
