import { render, staticRenderFns } from "./emptyRequests.vue?vue&type=template&id=2064ecd5&scoped=true"
import script from "./emptyRequests.vue?vue&type=script&lang=js"
export * from "./emptyRequests.vue?vue&type=script&lang=js"
import style0 from "./emptyRequests.vue?vue&type=style&index=0&id=2064ecd5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2064ecd5",
  null
  
)

export default component.exports